import { useDispatch, useSelector } from "react-redux";
import { useAxiosPrivateInventoryService } from "../../useAxiosPrivate";
import { useEffect, useState } from "react";
import { RState } from "../../../redux/feature/slice";
import { IAllProduct, IProductsContent } from "./inventoryService";
import {
  ADD_PRODUCT,
  ALL_PRODUCTS,
} from "../../../api/salon-api/inventory-service";
import {
  setAddProduct,
  setAllProducts,
  setDeleteProduct,
  setUpdateProduct,
} from "../../../redux/feature/inventoryService";

export const usePaginatedProducts = (
  initialPage = 1,
  initialSize = 5,
  all = false
) => {
  const allProducts = useSelector(
    (state: RState) => state.inventoryService.allProducts
  );

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  // const [currentPage, setCurrentPage] = useState(
  //   allProducts?.page || initialPage
  // );
  const [currentPage, setCurrentPage] = useState(initialPage);
  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)
  const [pageSize, setPageSize] = useState(initialSize);
  // const [pageSize, setPageSize] = useState(
  //   allProducts?.totalPages || initialSize
  // );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAllProducts = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = all
        ? {
          salonId: SalonDetails?.id
        }
        : {
            page: currentPage,
            size: pageSize,
            salonId: SalonDetails?.id
          };

      const response = await axiosPrivate.post<IAllProduct>(
        ALL_PRODUCTS,
        {...params, salonId: SalonDetails?.id}
      );
      console.log(response.data)

      if (response.status === 200) {
        dispatch(setAllProducts(response.data));
      } else {
        setError(new Error("Failed to fetch services"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // if (
    //   currentPage !== allProducts?.page ||
    //   pageSize !== allProducts?.totalPages ||
    //   allProducts?.content.length === 0
    // ) {
      fetchAllProducts();
    // } else {
      // setIsLoading(false);
    // }
  }, [currentPage, pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
    allProducts,
  };
};

export const useUpdateProduct = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)
  const updateProduct = async (data: IProductsContent) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors
    console.log("data", data)
    try {
      const response = await axiosPrivate.put<IProductsContent>(
        `${ADD_PRODUCT}/${data.id}`,
        { ...data, salonId: SalonDetails?.id }
      );

      if (response.status === 200) {
        dispatch(setUpdateProduct(response.data));
      } else {
        setError(new Error("Failed to update product"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error updating service:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateProduct,
  };
};

export const useCreateProduct = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const SalonDetails = useSelector((state: RState) => state?.SalonDetails?.Salon_data)
  const createProduct = async (data: IProductsContent) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.post<IProductsContent>(
        `${ADD_PRODUCT}`,
        { ...data, salonId: SalonDetails?.id}
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setAddProduct(response.data));
      } else {
        setError(new Error("Failed to create product"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error creating:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    createProduct,
  };
};

export const useDeleteProduct = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const deleteProduct = async (id: number) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.delete<IProductsContent>(
        `${ADD_PRODUCT}/${id}`
      );

      if (response.status === 200) {
        dispatch(setDeleteProduct(id));
      } else {
        setError(new Error("Failed to create product"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error creating:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    deleteProduct,
  };
};
