import { useEffect, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  customerCredSlice,
  deleteUser,
} from "../../../redux/feature/customerCredSlice";

// Dummy logo

// Router
import { useNavigate } from "react-router-dom";

// Component & Assets
import { BackGroundVideo } from "../../../assets";

// Form Value & Formik
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import {
  SIGNUP_FIELD_NAME,
  signupInitialValues,
} from "./formModal/initialValue";
import { validationSchemaSignup } from "./formModal/signupValidation";

// Constant
import { MUI_SIZE, MUI_VARIANT } from "../../../helper/Common/constant";
// MUI
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";

// Icon
import BadgeIcon from "@mui/icons-material/Badge";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import {
  DatePickerField,
  TimePickerField,
} from "../../../components/Mui/Formik/DateSelectorFormik";
import SelectField from "../../../components/Mui/Formik/SelectField";
import TransgenderIcon from "@mui/icons-material/Transgender";
import { SIGNUP_FORM_DATA } from "./SignUpConstant";
import { setAllCustomers } from "../../../redux/feature/customer";
import {
  getAllCustomers,
  registerCustomer,
} from "../../../api/salon-api/customer-apis";
import { getDateFormatYYYYMMDD } from "../../../helper/Common/DateFunctions";

// Tost
import { toast } from "react-toastify";
import SideBarActiveServices from "./SideBarActiveServices";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { SALON_ONBOARDING_ENDPOINT } from "../../../api/salon-api/salon-service";
import { setDetails } from "../../../redux/feature/salonDetailsSlice";
import { setLogo } from "../../../redux/feature/uploadLogoSlice";
import { setPaymentQR } from "../../../redux/feature/uploadPaymentSlice";
import { IMG_URL } from "../../../constants/baseURL";
import { removeAllSelectedService } from "../../../redux/feature/selectedService";
import { resetOrderBook } from "../../../redux/feature/orderBookSlice";
import { DELETE_ORDER_BOOK } from "../../../api/salon-api/log-book-service";

const InputProps = (icon) => {
  return {
    startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
  };
};

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allCustomerList =
    useSelector((state) => state?.customers?.allCustomers?.content) || [];

  const CustomerCred = useSelector((state) => state.CustomerCred);

  // const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const SalonDetails = useSelector((state) => state?.SalonDetails || {});
  const Auth = useSelector((state) => state?.auth || {});
  const Logo = useSelector((state) => state?.UploadLogo.logo);

  const [isOldCustomer, setIsOldCustomer] = useState(true);

  /**
   * Executes the necessary actions to update the state and navigate to the dashboard page.
   *
   * @param {Object} customerData - The customer data to be added to the user state.
   * @return {void} This function does not return anything.
   */
  const pageAndStateController = (customerData) => {
    console.log("customerdata", {
      token: customerData,
      statusCode: 200,
      isCustomer: true,
    });
    dispatch(
      addUser({ token: customerData, statusCode: 200, isCustomer: true })
    );
    toast("Successfully registered!");
    if (SalonDetails?.Salon_data?.salonTypeStatus === "MALE") {
      navigate("/customer/service/male");
    } else if (SalonDetails?.Salon_data.salonTypeStatus === "FEMALE") {
      navigate("/customer/service/female");
    } else {
      navigate("/customer/dashboard");
    }
  };

  /**
   * Handles the change event of the phone number input field.
   *
   * @param {Object} event - The change event object.
   * @param {Object} formikProps - The Formik props object.
   * @return {void} This function does not return anything.
   */
  const handlePhoneNumberChange = (event, formikProps) => {
    const { value, name } = event.target;
    const { setFieldValue } = formikProps;
    setFieldValue(name, value);

    // check length for valid mobile number
    if (value?.length === 10) {
      //find data from all customers list
      const isCustomerExist =
        allCustomerList.find(
          (customer) => Number(customer.mobileNo) === Number(value)
        ) || null;

      if (isCustomerExist) {
        setIsOldCustomer(true);
        // This is why coze of old structure
        pageAndStateController(isCustomerExist);
      } else {
        setIsOldCustomer(false);
      }
    }
  };

  /**
   * Handles the form submission for registering a customer.
   *
   * @param {Object} values - The form values.
   * @param {Object} options - The Formik options object.
   * @param {Function} options.setSubmitting - The function to set the submitting state.
   * @return {Promise<void>} A promise that resolves when the submission is complete.
   */
  const handleSubmit = async (values, { setSubmitting }) => {
    // Todo : status and term and condition by default will be accepted if needed change in future
    const payload = {
      ...values,
      [SIGNUP_FIELD_NAME.DATE]: getDateFormatYYYYMMDD(
        values[SIGNUP_FIELD_NAME.DATE].$d
      ),
      status: "ACTIVE",
      termAndCondition: "Accepted",
    };
    try {
      const response = await registerCustomer(payload);
      pageAndStateController(response);
      setSubmitting(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  /**
   * Retrieves all customers from the server.
   *
   * @return {Promise<void>} A promise that resolves when the customers have been retrieved and dispatched.
   */
  const getAllCustomersFn = async () => {
    const page = 1;
    const size = 10000; // Assuming we don't have 10000 customers
    try {
      const resp = await getAllCustomers({ page, size });
      // const resp = axiosPrivate({
      //   url: ALL_CUSTOMERS,
      //   params: {
      //     page,
      //     size,
      //   },
      // });
      dispatch(setAllCustomers(resp));
      await GetDetails();
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleBookLater = () => {
    toast("This feature is coming soon! ");
  };

  // const handleLogout = () => {
  //   localStorage.removeItem("auth")
  //   navigate("/")
  // };

  function handleEmployeeLogin() {
    navigate("/employeeLogin");
  }

  useEffect(() => {
    getAllCustomersFn();
    dispatch(deleteUser());
    dispatch(removeAllSelectedService());
    // Todo: write a condition for if redux have data then do not call this function
  }, []);

  const handleLogout = () => {
    // eslint-disable-next-line no-undef
    window.localStorage.removeItem("auth");
    navigate("/");
  };

  const GetDetails = async (id = Auth.data.salonId) => {
    try {
      if (!SalonDetails.Salon_data.length > 0) {
        const response = await axiosPrivate({
          method: "GET",
          url: `${SALON_ONBOARDING_ENDPOINT}/${id}`,
        });
        dispatch(setDetails(response.data));
        dispatch(
          setLogo(`${IMG_URL.BASE_SERVICE_IMG}${response.data.logoS3Path}`)
        );
        dispatch(
          setPaymentQR(
            `${IMG_URL.BASE_SERVICE_IMG}${response.data.paymentS3Path}`
          )
        );
      }
    } catch (error) {
      console.error("Error fetching Salon Details:", error);
    }
  };

  return (
    <div className="relative">
      <button
        className="absolute h-[55px] w-[55px] transition-all top-0 left-0 z-10 
      bg-gray-100 bg-opacity-60 backdrop-brightness-10 
      backdrop-blur-md rounded-md p-2
      lg:p-2 flex justify-center items-center"
        onClick={handleLogout}
      >
        <ExitToAppIcon color="action" />
      </button>
      <button
        className="absolute h-[55px] w-[55px] transition-all top-32 left-0 z-10 
      bg-gray-100 bg-opacity-60 backdrop-brightness-10 
      backdrop-blur-md rounded-md p-2
      lg:p-2 flex justify-center items-center"
        onClick={handleEmployeeLogin}
      >
        <EventAvailableIcon color="action" />
      </button>
      <SideBarActiveServices />
      <div className="relative h-screen w-full flex">
        <video
          src={BackGroundVideo}
          className="filter blur-sm img object-cover absolute inset-0
          lg:relative lg:w-3/5 lg:blur-none
        "
          muted
          autoPlay
          loop
        ></video>

        <Stack
          className="flex justify-center items-center  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-96 
          lg:relative lg:top-0 lg:left-0 lg:translate-x-0 lg:translate-y-0  lg:h-screen
          lg:w-2/5 lg:bg-gray-100 lg:bg-opacity-60 
        "
        >
          <Stack
            className=" bg-gray-100 bg-opacity-60 backdrop-brightness-10 backdrop-blur-md rounded-md p-4
            lg:p-8  flex items-center
          "
          >
            <img src={Logo} className="img h-[100px] w-[200px] my-5" />
            <Typography className="text-center " variant="h3" gutterBottom>
              Welcome to our Salon !
            </Typography>

            <Formik
              initialValues={signupInitialValues}
              validationSchema={validationSchemaSignup}
              onSubmit={handleSubmit}
              enableReinitialize
              validateOnChange={true}
            >
              {(formikProps) => {
                return (
                  <Form id="signup-form" className="w-full">
                    <Stack gap={2}>
                      <Field
                        variant={MUI_VARIANT}
                        size={MUI_SIZE}
                        component={TextField}
                        onChange={(e) =>
                          handlePhoneNumberChange(e, formikProps)
                        }
                        type="number"
                        placeholder="Please enter your phone number"
                        label="Phone Number *"
                        name={SIGNUP_FIELD_NAME.PHONE_NUMBER}
                        id={SIGNUP_FIELD_NAME.PHONE_NUMBER}
                        helperText="Enter your phone number"
                        InputProps={InputProps(<PhoneAndroidIcon />)}
                      />
                      {!isOldCustomer && <NewCustomer />}
                      <Divider />

                      {/* We will add Book later section in future*/}
                      <Stack width={"100%"} display={"none"}>
                        <Field
                          fullWidth
                          component={TimePickerField}
                          label="Time *"
                          name={SIGNUP_FIELD_NAME.DATE}
                          id={SIGNUP_FIELD_NAME.DATE}
                          size={MUI_SIZE}
                          textFieldProps={{
                            variant: MUI_VARIANT,
                            size: MUI_SIZE,
                            helperText: "Enter Time",
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      p={2}
                      spacing={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      <Button type="submit" variant="contained">
                        Book Now
                      </Button>
                      <Button variant="contained" onClick={handleBookLater}>
                        Book Later
                      </Button>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </Stack>
      </div>
    </div>
  );
}

const NewCustomer = () => {
  return (
    <Stack gap={2} width={"100%"}>
      <Field
        variant={MUI_VARIANT}
        size={MUI_SIZE}
        component={TextField}
        placeholder="Please enter your full name"
        label="Full Name *"
        name={SIGNUP_FIELD_NAME.FULL_NAME}
        id={SIGNUP_FIELD_NAME.FULL_NAME}
        helperText="Please enter your full name"
        InputProps={InputProps(<BadgeIcon />)}
      />
      <Field
        variant={MUI_VARIANT}
        size={MUI_SIZE}
        component={TextField}
        placeholder="Please enter your Email"
        label="Email"
        name={SIGNUP_FIELD_NAME.EMAIL}
        id={SIGNUP_FIELD_NAME.EMAIL}
        helperText="Please enter your Email"
        InputProps={InputProps(<EmailIcon />)}
      />
      <Field
        size={MUI_SIZE}
        component={SelectField}
        label="Gender *"
        name={SIGNUP_FIELD_NAME.GENDER}
        Icon={TransgenderIcon}
        data={SIGNUP_FORM_DATA.GENDER.OPTION}
        async
        id={SIGNUP_FIELD_NAME.GENDER}
        helperText="Select your gender"
        selectFieldProps={{
          variant: MUI_VARIANT,
        }}
        fullWidth
      />
      <Stack sx={{ transform: "translateY(-10px)", position: "relative" }}>
        <Field
          fullWidth
          component={DatePickerField}
          disableFuture
          label="Date of Birth *"
          name={SIGNUP_FIELD_NAME.DATE}
          id={SIGNUP_FIELD_NAME.DATE}
          size={MUI_SIZE}
          textFieldProps={{
            variant: MUI_VARIANT,
            size: MUI_SIZE,
            helperText: "Enter date of birth",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SignUp;
