import { Field, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { InventoryContent } from "../../../../../../hooks/api/inventory-service/inventoryService";
import { Stack } from "@mui/material";
import { MUI_SIZE, MUI_TEXT_FIELD_HIGHT, MUI_VARIANT } from "../../../../../../helper/Common/constant";
import SelectField from "../../../../../../components/Mui/Formik/SelectField";
import { INVENTORY_FIELD_NAMES } from "../formModal/inventoryInitialValue";
import { TextField } from "formik-mui";
import { usePaginatedProducts } from "../../../../../../hooks/api/inventory-service/useAllProduct";
import { usePaginatedVendorController } from "../../../../../../hooks/api/salonService/vendorController/useAllVendor";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScaleIcon from '@mui/icons-material/Scale';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

interface IProps {
  formikProps: FormikProps<InventoryContent>;
}

const InventoryFormUi = ({ formikProps }: IProps) => {
  const { allProducts, isLoading } = usePaginatedProducts(1, 1, true);
  const { vendorData, isLoading: isLoadingVendor } = usePaginatedVendorController(1, 1, true);

  const [filteredVendors, setFilteredVendors] = useState<any>([]);
  const [measurementUnits, setMeasurementUnits] = useState<any>([]);

  // Fetch product and vendor list
  const productList = allProducts?.content?.map((product) => ({
    value: product.id,
    label: product.name,
  })) || [];

  // console.log('productList',allProducts?.content)
  

  useEffect(() => {
    if (formikProps.values.productId) {
      // Fetch vendors related to the selected product
      const vendorsForProduct = allProducts?.content?.find( product => product.id === formikProps.values.productId)?.vendorList;

      const vendorList = vendorsForProduct?.map((vendor) => ({
        value: vendor.id,
        label: vendor.name,
      })) || [];
      setFilteredVendors(vendorList);
      formikProps.setFieldValue(INVENTORY_FIELD_NAMES.vendorId, ''); // Clear vendor field
  
      // Set measurement unit according to the selected products
      const selectedProduct = allProducts?.content?.find((item) => item.id === formikProps.values.productId);
  
      if (selectedProduct) {
        // Update the formik value with the selected product's measurement unit
        formikProps.setFieldValue(INVENTORY_FIELD_NAMES.measurementUnit, selectedProduct.measurementUnit || '');
      } else {
        // Clear the measurement unit if no product is selected
        formikProps.setFieldValue(INVENTORY_FIELD_NAMES.measurementUnit, '');
      }
    }
  }, [formikProps.values.productId]);
  


  return (
    <Stack py={1} flexDirection={"column"} spacing={2}>
      <Stack direction={"row"} spacing={2}>
        {/* Product Selection */}
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={productList}
          async={isLoading}
          placeholder="Select Product"
          label="Product"
          name={INVENTORY_FIELD_NAMES.productId}
          id={INVENTORY_FIELD_NAMES.productId}
          sx={{ height: MUI_TEXT_FIELD_HIGHT }}
          Icon={ShoppingCartIcon}
        />

        {/* Vendor Selection - updates based on product */}
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={filteredVendors}
          async={isLoadingVendor}
          placeholder="Select Vendor"
          label="Vendor"
          name={INVENTORY_FIELD_NAMES.vendorId}
          id={INVENTORY_FIELD_NAMES.vendorId}
          sx={{ height: MUI_TEXT_FIELD_HIGHT }}
          Icon={ShoppingBasketIcon}
        />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        {/* Measurement Unit Selection - updates based on vendor */}
        <Field
          disabled
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          // data={measurementUnits}
          async={true}
          placeholder="Select Measurement Unit"
          label="Measurement Unit"
          name={INVENTORY_FIELD_NAMES.measurementUnit}
          id={INVENTORY_FIELD_NAMES.measurementUnit}
          sx={{ height: MUI_TEXT_FIELD_HIGHT }}
          Icon={ScaleIcon}
        />

        {/* Quantity */}
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          async={true}
          placeholder="Select Quantity"
          label="Quantity"
          name={INVENTORY_FIELD_NAMES.quantity}
          id={INVENTORY_FIELD_NAMES.quantity}
          sx={{ height: MUI_TEXT_FIELD_HIGHT }}
          InputProps={{ startAdornment: <ProductionQuantityLimitsIcon /> }}
        />
      </Stack>

      {/* Threshold */}
      <Stack direction={"row"} spacing={2}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          async={true}
          placeholder="Select Threshold"
          label="Threshold"
          name={INVENTORY_FIELD_NAMES.threshold}
          id={INVENTORY_FIELD_NAMES.threshold}
          sx={{ height: MUI_TEXT_FIELD_HIGHT }}
          InputProps={{ startAdornment: <DataThresholdingIcon /> }}
        />
      </Stack>
    </Stack>
  );
};

export default InventoryFormUi;
