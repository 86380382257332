import { Field, FieldInputProps, Form, Formik, FormikProps } from "formik";
import React from "react";
import {
  CONFIG_SERVICE_FIELD_NAME,
  initialValueConfigService,
} from "./formModal/initialValueConfigService";
import { Button, Stack, TextField as MuiTextField } from "@mui/material";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../../helper/Common/constant";
import { TextField } from "formik-mui";

import { FieldIcon } from "../../../../../../components/Mui/Formik/FieldIcon";
import SelectField from "../../../../../../components/Mui/Formik/SelectField";

// icons
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import BadgeIcon from "@mui/icons-material/Badge";
import TransgenderIcon from "@mui/icons-material/Transgender";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { validationConfigService } from "./formModal/validationConfigService";
import useServiceCategory from "../../../../../../hooks/api/useServiceCategory";
import {
  IRequestBodyAddService,
  IServiceCategory,
} from "../types/configService";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import {
  handleUploadServiceImg,
  requestBodyAddService,
} from "./formModal/requestFact";
import { useDispatch, useSelector } from "react-redux";
import { RState } from "../../../../../../redux/feature/slice";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import { SALON_SERVICE_ENDPOINT } from "../../../../../../api/salon-api/salon-service";
import { IDialogueMode, SERVICE_FORM_MODE } from "../ConfigServiceTable";
import { useUpdateServices } from "../configServiceApiHook";
import { setAddService } from "../../../../../../redux/feature/salonServiceSlice";
import MultiSelectFormik from "../../../../../../components/Mui/Formik/MultiSelectFormik";
import { usePaginatedProducts } from "../../../../../../hooks/api/inventory-service/useAllProduct";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IMG_URL } from "../../../../../../constants/baseURL";
import { toast } from "react-toastify";
import { UploadImage } from "../../../../../../components/Mui/Formik/UploadImage";
type AddServiceFormProps = {
  handleDialogueClose: () => void;
  openDialogueEdit: IDialogueMode;
  // setOpenDialogueEdit: React.Dispatch<React.SetStateAction<IDialogueMode>>;
};

const AddServiceForm = ({
  handleDialogueClose,
  openDialogueEdit,
}: AddServiceFormProps) => {
  const { loadingServiceCategory, serviceListData } = useServiceCategory();
  const { allProducts } = usePaginatedProducts(0, 0, true);

  const SalonDetails = useSelector(
    (state: RState) => state?.SalonDetails?.Salon_data
  );
  const dispatch = useDispatch();

  const axiosPrivate = useAxiosPrivate();

  const { updateService } = useUpdateServices();

  const handleSubmit = async (values: IRequestBodyAddService) => {
    if (openDialogueEdit.mode === SERVICE_FORM_MODE.EDIT) {
      try {
        await updateService({
          ...values,
          id: openDialogueEdit.data?.id,
          salonId: SalonDetails?.id,
          gender:
            SalonDetails?.salonTypeStatus &&
            SalonDetails?.salonTypeStatus !== "UNISEX"
              ? SalonDetails.salonTypeStatus.charAt(0) +
                SalonDetails.salonTypeStatus.slice(1).toLowerCase()
              : serviceListData?.content?.find((item) => item.id === values?.serviceCategoryId)?.gender,
        });
        handleDialogueClose();
      } catch (error) {
        console.error("error", error);
      }
      return;
    }

    const requestBody = requestBodyAddService({
      ...values,
      salonId: SalonDetails?.id,
      gender:
        SalonDetails?.salonTypeStatus &&
        SalonDetails?.salonTypeStatus !== "UNISEX"
          ? SalonDetails.salonTypeStatus.charAt(0) +
            SalonDetails.salonTypeStatus.slice(1).toLowerCase()
          : serviceListData?.content?.find((item) => item.id === values?.serviceCategoryId)?.gender,
    });
    try {
      const response = await axiosPrivate.post(
        SALON_SERVICE_ENDPOINT,
        requestBody
      );
      toast.success("Service Added Successfully!");
      let imgResponse = { key: "" };
      // upload img api here
      if (values.imgUpload) {
        console.log(values, axiosPrivate, openDialogueEdit.data?.id);
        imgResponse = await handleUploadServiceImg(
          values,
          axiosPrivate,
          response.data?.id
        );
      }

      dispatch(
        setAddService({ ...response.data, imageS3path: imgResponse?.key })
      );
      handleDialogueClose();
      console.log("response", response);
    } catch (error) {
      console.error("error", error);
    }
  };

  const serviceDataList =
    serviceListData?.content
      ?.filter((item) => item.status === "ACTIVE")
      .map((ele: IServiceCategory) => {
        return {
          label:
            SalonDetails?.salonTypeStatus === "UNISEX"
              ? `${ele?.categoryName}`
              : ele?.categoryName,
          value: ele.id,
          gender: ele.gender,
        };
      }) || [];

  const productDataList =
    allProducts?.content
      ?.filter((item) => item.status === "ACTIVE")
      .map((ele) => {
        return {
          label: ele.name,
          value: ele.id,
        };
      }) || [];

  function getImg(
    image: string | File | Blob | undefined | null
  ): string | undefined | null {
    if (typeof image === "string") {
      return image;
    } else if (image instanceof File) {
      // Handle File type
      return URL.createObjectURL(image);
    } else if (image instanceof Blob) {
      // Handle Blob type
      return URL.createObjectURL(image);
    } else {
      return undefined;
    }
  }

  return (
    <Stack spacing={2}>
      <Formik
        initialValues={initialValueConfigService(openDialogueEdit)}
        validationSchema={validationConfigService}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange={true}
      >
        {(f) => {
          return (
            <Form id="add-service-form">
              <Stack py={1} flexDirection={"column"} spacing={2}>
                <Stack direction={"row"} spacing={2}>
                  <Field
                    fullWidth
                    variant={MUI_VARIANT}
                    size={MUI_SIZE}
                    component={TextField}
                    placeholder="Enter service name"
                    label="Service Name"
                    type="text"
                    name={CONFIG_SERVICE_FIELD_NAME.SERVICE_NAME}
                    id={CONFIG_SERVICE_FIELD_NAME.SERVICE_NAME}
                    sx={{
                      height: MUI_TEXT_FIELD_HIGHT,
                    }}
                    // helperText="Please enter your name"
                    InputProps={FieldIcon(<BadgeIcon />)}
                  />

                  <Field
                    fullWidth
                    variant={MUI_VARIANT}
                    size={MUI_SIZE}
                    component={TextField}
                    placeholder="Enter Average time in minutes"
                    label="Average Time (minutes)"
                    type="text"
                    name={CONFIG_SERVICE_FIELD_NAME.SERVICE_TIME}
                    id={CONFIG_SERVICE_FIELD_NAME.SERVICE_TIME}
                    sx={{
                      height: MUI_TEXT_FIELD_HIGHT,
                    }}
                    // helperText="Please enter your name"
                    InputProps={FieldIcon(<BrowseGalleryIcon />)}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <Field
                    fullWidth
                    variant={MUI_VARIANT}
                    size={MUI_SIZE}
                    component={SelectField}
                    async={loadingServiceCategory}
                    data={serviceDataList}
                    placeholder="Select service category"
                    label="Service Category"
                    name={CONFIG_SERVICE_FIELD_NAME.SERVICE_CATEGORY_ID}
                    id={CONFIG_SERVICE_FIELD_NAME.SERVICE_CATEGORY_ID}
                    sx={{
                      height: MUI_TEXT_FIELD_HIGHT,
                    }}
                    Icon={SettingsSuggestIcon}
                  />
                  {/* {SalonDetails?.salonTypeStatus === "UNISEX" && (
                    <Field
                      fullWidth
                      variant={MUI_VARIANT}
                      size={MUI_SIZE}
                      component={SelectField}
                      data={[
                        {
                          label: "Male",
                          value: "Male",
                        },
                        {
                          label: "Female",
                          value: "Female",
                        },
                      ]}
                      placeholder="Select your gender"
                      label="Gender"
                      name={CONFIG_SERVICE_FIELD_NAME.SERVICE_GENDER}
                      id={CONFIG_SERVICE_FIELD_NAME.SERVICE_GENDER}
                      sx={{
                        height: MUI_TEXT_FIELD_HIGHT,
                      }}
                      Icon={TransgenderIcon}
                    />
                  )} */}
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <Field
                    fullWidth
                    variant={MUI_VARIANT}
                    size={MUI_SIZE}
                    component={TextField}
                    placeholder="Enter service price"
                    label="Service Price"
                    type="text"
                    name={CONFIG_SERVICE_FIELD_NAME.SERVICE_PRICE}
                    id={CONFIG_SERVICE_FIELD_NAME.SERVICE_PRICE}
                    sx={{
                      height: MUI_TEXT_FIELD_HIGHT,
                    }}
                    // helperText="Please enter your name"
                    InputProps={FieldIcon(<PriceCheckIcon />)}
                  />
                  <Field
                    component={MultiSelectFormik}
                    name={CONFIG_SERVICE_FIELD_NAME.PRODUCT_IDS}
                    id={CONFIG_SERVICE_FIELD_NAME.PRODUCT_IDS}
                    fullWidth
                    variant={MUI_VARIANT}
                    size={MUI_SIZE}
                    data={productDataList || []}
                    placeholder="Select Products"
                    label="Products"
                    sx={{
                      height: MUI_TEXT_FIELD_HIGHT,
                    }}
                    Icon={AddShoppingCartIcon}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <Field
                    fullWidth
                    variant={MUI_VARIANT}
                    size={MUI_SIZE}
                    component={UploadImage}
                    placeholder="Upload service image"
                    label="Image"
                    type="file"
                    name={CONFIG_SERVICE_FIELD_NAME.IMG_UPLOAD}
                    id={CONFIG_SERVICE_FIELD_NAME.IMG_UPLOAD}
                    sx={{
                      height: MUI_TEXT_FIELD_HIGHT,
                    }}
                    // helperText="Please enter your name"
                    InputProps={FieldIcon(<UploadFileIcon />)}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <img
                    // src={`${IMG_URL.BASE_SERVICE_IMG}${f?.values?.imageS3path}`}
                    src={
                      getImg(
                        f?.values?.imgUpload
                          ? f?.values?.imgUpload
                          : `${IMG_URL.BASE_SERVICE_IMG}${f?.values?.imageS3path}`
                      ) || " "
                    }
                    alt=""
                  />
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"right"}
                >
                  <Button variant={"contained"} type="submit">
                    {openDialogueEdit.mode === SERVICE_FORM_MODE.EDIT
                      ? "Update Service"
                      : " Add Service"}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default AddServiceForm;
